import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbd-spinner',
  templateUrl: './sbd-spinner.component.html',
  styleUrls: ['./sbd-spinner.component.scss']
})
export class SbdSpinnerComponent {

}
