import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import { doc, getDoc, } from 'firebase/firestore';
import { GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider, signInWithPopup, OAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, getAuth } from "firebase/auth";



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth: any;
  private smallBatchDevsEmail = 'smallbatchdevs@gmail.com';
  private userSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  ui$: Observable<any | null> = this.userSubject.asObservable();
  public user: any;

  db: any;
  // auth: any;
  // public user$: Observable<any> = this.auth.user.pipe(shareReplay(1));

  constructor(private router: Router, private ngZone: NgZone) {
    //  this.user$.subscribe();
    // const use = this.user$.pipe(
    //   map(user => user ? user.uid : null) // Extract the uid property
    // ).subscribe(uid => {
    //   console.log(uid);
    //   this.localFire(uid);
    //   // You can now use this.uid in your template or component logic.
    // });
    // onAuthStateChanged(this.auth, (user) => {
    //   if (user) {
    //     this.user = user
    //     // ...
    //   } else {
    //     // User is signed out
    //     // ...
    //   }
    // });


  }

  setFire(db, auth) {

    this.db = db;
    this.auth = auth;
  }

  setUser(user) {
    this.user = user;
    this.userSubject.next(user)
    console.log(user)

  }

  setLogged() {
    localStorage.setItem('isLoggedIn', 'true');
  }

  async localFire(uid) {
    //get uid and bring in account details from firebase
    console.log(uid)
    try {
      console.log(uid)
      const ref = doc(this.db, 'users', uid);
      const userDoc = await getDoc(ref)
      console.log("trying")
      console.log(userDoc.data())
      if (userDoc.exists) {
        // Do something with the user document data here
        const userData = userDoc.data();


        this.user = userData;
        console.log(this.user)
        this.userSubject.next(this.user);
        localStorage.setItem('user', JSON.stringify(this.user));
        JSON.parse(localStorage.getItem('user')!);
      } else {

        // Handle the case where the document doesn't exist
        console.log("User document not found.");
      }
    } catch (error) {
      // Handle any errors that might occur during the Firestore operation
      console.error("Error fetching user data:", error);
    };

  }

  signInWithGoogle() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {

        this.setLogged();
        this.localFire(res.user.uid);
        return this.ngZone.run(() => this.router.navigate(['/home']));
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });



  }


  signInWithEmail(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password)
      .then((res) => {
        console.log('AuthService::Successful Email login', res);
        this.setLogged();

        this.localFire(res.user.uid);
        return this.ngZone.run(() => this.router.navigate(['/home']));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  signUpWithEmail(email: string, password: string) {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        this.setLogged();

        console.log('AuthService::Successful Twitter login', res);
        return this.ngZone.run(() => this.router.navigate(['/']));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });


  }

  getUser() {
    return this.user;
  }

  // isSmallBatchDevsLoggedIn$(): Observable<boolean> {
  //   return this.user$.pipe(map(user => user && user.email));
  // }

  logout() {
    const auth = getAuth();
    signOut(auth).then(() => {
      this.userSubject.next("none")
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });

  }


  // Not Yet Implemented
  signInWithFacebook() {
    const auth = getAuth();
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {

        this.setLogged();
        this.localFire(res.user.uid);
        return this.ngZone.run(() => this.router.navigate(['/home']));
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });


  }


  // Not Yet Implemented
  signInWithApple() {
    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');
    signInWithPopup(auth, provider)
      .then((res) => {
        this.setLogged();

        this.localFire(res.user.uid);
        return this.ngZone.run(() => this.router.navigate(['/home']));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        console.log(error)
      });

  }
}
