import { Component } from '@angular/core';
import { AuthService } from '../../../../shared/services/authentication/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  currentUser$: Observable<string> = this.authService.ui$.pipe(
    map((user) => user ? user.email : null)
  );

  constructor(private authService: AuthService, private router: Router) {
  }

  logoutUser() {
    this.router.navigate(["/login"])
    this.authService.logout();
  }
}
