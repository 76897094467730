import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './modules/home/pages/homepage/homepage.component';
import { EditPostComponent } from './modules/home/pages/edit-post/edit-post.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { LoginComponent } from './modules/home/pages/login/login.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DonateComponent } from './shared/components/donate/donate.component';
import { AboutComponent } from './modules/home/pages/about/about.component';
import { SbdSpinnerComponent } from './shared/components/sbd-spinner/sbd-spinner.component';
import { NavComponent } from './modules/home/pages/homepage-nav/nav.component';
import { SuccessComponent } from './modules/home/pages/success/success.component';
import { FailedComponent } from './modules/home/pages/failed/failed.component';




@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    NavComponent,
    EditPostComponent,
    LoginComponent,

    DonateComponent,
    AboutComponent,
    SbdSpinnerComponent,
    SuccessComponent,
    FailedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    EditorModule,
    FormsModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' })
  ],
  providers: [{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
