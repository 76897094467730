import { Component } from '@angular/core';
import { AuthService } from '../../../../shared/services/authentication/auth.service';

interface EmailPassword {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(private authService: AuthService) { }

  emailEnabled = false;
  userEmail = '';
  userPassword = '';
  errorMessage = '';

  googleLogin() {
    this.authService.signInWithGoogle()
  }

  appleLogin() {
    this.authService.signInWithApple();

  }


  emailPasswordLogin() {
    if (this.userEmail && this.userPassword) {
      this.authService.signInWithEmail(this.userEmail, this.userPassword)
    }
  }

  emailPasswordSignUp() {
    if (this.userEmail && this.userPassword) {
      this.authService.signUpWithEmail(this.userEmail, this.userPassword)
    }
  }



  // Not Yet Implemented
  facebookLogin() {
    this.authService.signInWithFacebook()
  }
}
