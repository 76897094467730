
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './modules/home/pages/homepage/homepage.component';
import { EditPostComponent } from './modules/home/pages/edit-post/edit-post.component';
import { LoginComponent } from './modules/home/pages/login/login.component';
import { AboutComponent } from './modules/home/pages/about/about.component';
import { AuthGuard } from './guards/auth.guard';
import { SuccessComponent } from './modules/home/pages/success/success.component';
import { FailedComponent } from './modules/home/pages/failed/failed.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    pathMatch: 'full',
    component: HomepageComponent
  },

  {
    path: 'home',

    pathMatch: 'full',
    component: HomepageComponent,
    children: [

      {
        path: 'cancel',
        component: FailedComponent,
      },
    ],
  },
  {
    path: 'success',
    component: SuccessComponent,
  },
  {
    path: 'about',
    component: AboutComponent
  },
  // {
  //   path: 'login',
  //   component: LoginComponent
  // },
  {
    path: 'login',
    component: LoginComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
