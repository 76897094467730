import { Component, OnInit } from '@angular/core';
import { BlogPost } from '../../../../shared/models/blog-post';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../../shared/services/authentication/auth.service';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { Contact } from '../../../../../../shared/models/contact';
import 'firebase/functions';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { objectExists } from '../../../../shared/services/utilites/utilities.service';
import axios from 'axios';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  //readonly isUserSmallBatchDevs$: Observable<boolean> = this.authService.isSmallBatchDevsLoggedIn$();
  stripe!: Stripe;
  readonly currentUser$ = this.authService.getUser();
  private uiSubscription: Subscription;
  user: any;
  price1: string = "price_1Ki13xJd3fzjfrRusZYZkkNd";
  price2: string = "price_1Ki15FJd3fzjfrRubDouQ7Im";
  price3: string = "price_1Ki173Jd3fzjfrRuDItIZYoU";
  doesNotHaveSubs$: boolean = true;

  constructor(private router: Router, private authService: AuthService) {

    this.uiSubscription = this.authService.ui$.subscribe(user => {
      console.log(user)
      if (user != null) {
        console.log(user)
        this.user = user;
        if (user.tier != "Free") {
          this.doesNotHaveSubs$ = false
        }
        else {
          this.doesNotHaveSubs$ = true;
        }

      }
      // You can now use this.uid in your template or component logic.
    });

  }

  ngOnInit(): void {





  }

  editPost(postUid?: string) {
    // if (postUid) {
    //   this.router.navigate([`/edit/${postUid}`]);
    // } else {
    //   this.router.navigate([`/edit/${this.database.getNewUid()}`]);
    // }
  }

  readPost(postUid: string) {
    this.router.navigate([`/post/${postUid}`]);
  }

  addContactToDatabase(email, firstName) {
    // const contact: Contact = { email, firstName };
    // // use email as the firebase document uid in case the user tries to sign up multiple times.
    // // emails are safe to use as the uid as they are guaranteed to be unique.
    // return this.database.set(contact, `contacts/${email}`);
  }



  goPortal() {

    axios.post('https://auth.settrippn.com/create-customer-portal-session', {
      id: this.currentUser$.stripeId,
    })
      .then(async (response: any) => {

        // window.location.href = "https://checkout.stripe.com/c/pay/" + response.data.stripeSessionId
        console.log('portal opening', response);
        if (response) {
          const sessionData = await response.data;
          // Redirect the user to the Customer Portal URL
          window.location.href = sessionData.url;
        }
      })
      .catch(error => {
        console.error('Error creating subscription:', error);
        // Handle subscription creation error
      });

  }


  subscribe(price: string): void {
    if (this.currentUser$) {
      console.log(price)
      axios.post('https://auth.settrippn.com/subscriptions', {
        stripeId: this.user.stripeId,
        //  email: this.currentUser$?.email, // Replace with the user's email
        priceId: price, // Replace with the selected price ID

      })
        .then(response => {

          // window.location.href = "https://checkout.stripe.com/c/pay/" + response.data.stripeSessionId
          console.log('Subscription being created:', response);
          this.redirectToCheckout(response.data.stripeSessionId)
          // if (this.user !== null) {
          //   console.log("paid")
          //   const userId = this.user.id; // Replace with the actual user ID
          //   this.updateUserTier(userId, 'Paid');

          // }
          // Handle successful subscription creation
        })
        .catch(error => {
          console.error('Error creating subscription:', error);
          // Handle subscription creation error
        });
    }
  }

  async redirectToCheckout(sessionId: string) {

    const stripe = await loadStripe('pk_live_51HFk4zJd3fzjfrRu7Rlrq6gDFX0uhuMD4e6r0urJtHioSvL0knYulOQuEnXctbOzRAdvf9PZ7JdnZ8lTRUTWtOnF00CrTxPVVu');

    if (stripe !== null) {
      stripe.redirectToCheckout({ sessionId })
        .then((result: { error: any; }) => {
          console.log(result)
          if (result.error) {
            console.error('Failed to redirect to checkout:', result.error);
            // Handle error
          }
        });

    }

  }








  async sendToCustomerPortal() {



    axios.post('https://www.auth.settrippn.com/create-customer-portal-session', this.user.stripeId).then(
      (response: any) => {
        // Redirect the user to the returned URL using Angular's Router or window.location.href
        window.location.href = response.url;
      },
      (error) => {
        console.error('Error:', error);
      }
    );

    // had to update firebase.app().functions() to firebase.default.functions() and
    // removed the region from the functions call (from stripe firebase extension docs)
    // const functionRef = firebase.default
    //   .functions()
    //   .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    // const { data } = await functionRef({ returnUrl: window.location.origin });
    // console.log(data);
    // window.location.assign(data.url);
  }
}