import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/authentication/auth.service';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { environment } from 'src/environments/environment';
import { getFirestore } from 'firebase/firestore';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // Dependency Inject our auth service here so it initializes before AuthGuard
  constructor(private auth: AuthService) {
  }

  ngOnInit() {
    this.loadFB();


  }

  loadFB() {
    console.log("load")
    const app = initializeApp(environment.firebaseConfig);


    // Initialize Firebase Authentication and get a reference to the service
    const auth = getAuth(app);
    const db = getFirestore(app);
    this.auth.setFire(db, auth);

  }
}
